/* Cards */

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  background: #fff;

  .img-tile {
    position: relative;
    height: 250px;
    overflow: hidden;

    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }

  .img-tile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .post-title {
    background: var(--prime-color-transparent);
    color: #fff;
    font-size: 18px;
    height: 65px;
    padding: 0 1rem;
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    line-height: 1;
    display: flex;
    align-items: center;
    z-index: 10;
    justify-content: space-between;
  }

  .date {
    font-size: 80%;
    font-weight: bolder;
    margin-left: 20px;
  }

  .post-content {
    align-self: stretch;
    margin: 1rem;
  }

  .read-more-link {
    background: #7a9e1a;
    display: block;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-size: 0.8rem;
    margin: auto 0 0;
    height: 30px;
    line-height: 30px;

    &::after {
      border-bottom-width: 3px;
    }
  }
}
