.search-header {
  max-width: 1260px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto 25px;

  h2 {
    margin-bottom: 1rem;
    color: #0072b5;
    font-size: 1.8rem;
  }
}

#searchform input[type="submit"],
#searchform input[type="text"] {
  border: 1px solid #575757;
  padding: 3px;
}
