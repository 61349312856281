.card-deck {
  display: grid;
  grid-gap: 1.5rem;
  width: 100%;
  padding: 0;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  grid-auto-rows: max-content;

  &.big-first-card {
    @media screen and (min-width: 767px) {
      .card:first-child {
        grid-column: span 2;

        .post-content {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.navigation {
  width: 100%;
  background: #fff;
  padding: 1rem;
  margin: 2rem 0 0;
  text-align: center;
  box-shadow: var(--box-shadow);
}
