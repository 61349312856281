@import "properties";

*,
:after,
:before {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: inherit;
  text-decoration: none;
  line-height: 1.25;
  box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img,
svg,
video {
  max-width: 100%;
  height: auto;
}

svg {
  max-height: 100%;
}

body,
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
  font-size: 1rem;
}

textarea {
  border: 1px solid #ddd;
}

html {
  width: 100%;
  height: 100vh;
}

body {
  font-family: Roboto, Arial, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  box-sizing: border-box;
  background: #aaa;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

p {
  margin: 0 0 0.8rem;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: #333;
  position: relative;

  & :after {
    border: none;
    transition: width, 0.3s, position, 0.3s;
    position: absolute;
    width: 0;
    left: 50%;
    bottom: 0;
    display: block;
    content: "";
    border-bottom: 1px solid;
  }

  &:hover {
    color: gray;

    &:after {
      width: 100%;
      left: 0;
    }
  }
}

main {
  max-width: 1260px;
  padding: 0 1rem;
  margin: 0 auto 25px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.main-title {
  color: #fff;
  font-size: 40px;
  display: inline-block;
  margin: 0 0 1rem;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.container {
  max-width: 1260px;
  padding: 0 30px;
  margin: 0 auto;
}

.post.card h2 {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0rem 0 0 0;
}

.page-heading {
  padding: 1rem 1rem;
  margin: 0 auto 25px;
  width: 100%;
  font-size: 1.5rem;
  background: #fff;
  color: var(--prime-color);
}

// @import "card-deck";
@import "card-deck";
@import "card";
@import "header";
@import "mobile";
@import "comments";
@import "footer";
@import "search";
@import "wp";

#wp-block-categories-1 {
  max-width: 220px;
}
