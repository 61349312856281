header {
  display: grid;
  width: 100%;
  background: var(--prime-color);
  margin-bottom: 35px;
  box-shadow: var(--box-shadow);
  gap: 1rem;
  grid-template-columns: 200px 1fr 300px;

  .searchform {
    margin: 1rem 1rem 1rem auto;
  }

  @media screen and (max-width: 880px) {
    grid-template-columns: 1fr 300px;

    .main-title {
      padding: 0.5rem 0.5rem 0 0.5rem;
    }
  }

  @media screen and (max-width: 670px) {
    display: block;

    .main-title {
      margin: 0;
    }

    .searchform {
      margin: 0 0.5rem 0.5rem;
      display: block;
    }
  }
}

.main-nav {
  display: flex;
  grid-column: 2 / 4;
  grid-row: 2 / 3;
  padding: 0 0.5rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  li {
    display: block;
    margin: 0 0.5rem 0 0;
  }

  a {
    color: #ffffff;
    background-color: #7a9e1a;
    display: block;
    padding: 0.5rem 1rem;
  }

  select {
    color: #ffffff;
    background-color: #7a9e1a;
    display: block;
    padding: 0.5rem 1rem;
  }

  @media screen and (max-width: 880px) {
    grid-column: 1 / 3;
  }

  @media screen and (max-width: 470px) {
    flex-direction: column;

    li {
      display: block;
      margin: 0 0.5rem 0.5rem 0;
      flex: 1 1 50%;
    }
  }
}

.header-logo__img {
  max-width: 200px;
  width: 100%;
  display: block;
}

.header-logo__link {
  background: transparent;
  padding: 0;
  background: #fff;
  display: block;
  padding: 0.5rem 1rem;
  grid-row: 1 / 3;

  @media screen and (max-width: 880px) {
    display: none;
  }
}
