footer {
  background: #fff;
  box-shadow: var(--box-shadow);
  padding: 1rem 0;

  .menu {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 660px) {
      flex-direction: column;
      align-items: center;

      a {
        display: block;
        padding: 0.5rem;
        width: 100%;
        text-align: center;
      }
    }
  }

  .copy {
    margin-top: 40px;
    text-align: center;
    font-size: 70%;
    font-size: 0.7rem;
  }
}
