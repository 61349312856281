.comments {
  background: #fff;
  margin: 2rem auto;
  padding: 1rem;

  h3 {
    color: var(--prime-color);
    font-size: 2rem;
    margin: 0 0 1rem;
    border-bottom: 1px solid;
  }

  .comment {
    margin: 1rem 0;
    box-shadow: var(--box-shadow);
    padding: 1rem;
    max-width: 50ch;
  }

  .comment-author {
    font-weight: bolder;
    float: left;
  }

  .comment-date {
    float: right;
  }

  .comment-content {
    clear: both;
  }

  hr {
    clear: both;
    margin: 10px 0;
    border: 2px solid #7a9e1a;
  }
}

.comment-respond {
  margin: 2rem auto 0;

  h3 {
    font-weight: bolder;
    font-size: 120%;
    margin-bottom: 20px;
  }

  label {
    display: block;
    font-weight: bolder;
  }

  textarea {
    resize: vertical;
    width: 100%;
    max-width: 70ch;
  }

  input,
  textarea {
    box-shadow: var(--box-shadow);
    border: 1px solid #ddd;
  }

  input {
    padding: 0.5rem 1rem;
    margin: 0;
    display: inline-block;
    max-width: 70ch;
  }
}
